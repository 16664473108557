<template>
  <div class="container add-country-button-component">
    <BaseDialog
      :title="title"
      append-to-body
      :dialogVisible.sync="dialog"
      width="824px"
      class="add-country-button-add-dialog dd-country-button-dialog-warpper"
    >
      <div class="whole-warpper" :loading="loading">
        <div class="left">
          <div class="search-warpper">
            <el-input
              size="mini"
              v-model="content"
              placeholder="输入国家/地区名称、二字码"
              style="width: 300px"
              clearable
            >
              <i class="el-icon-search" style="line-height: 30px" slot="suffix"> </i
            ></el-input>
          </div>
          <div class="continent-warpper">
            <el-radio-group size="small" class="ml15" v-model="seletcedId" border>
              <el-radio-button label="all"> 全部 </el-radio-button>
              <el-radio-button :label="id" :key="id" v-for="{ id, itemName } in continentDictCode">
                {{ itemName }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <div class="continent-select-warpper">
            <el-checkbox
              :indeterminate="isIndeterminate"
              class="all"
              v-model="checkAll"
              v-if="filterCountryList && filterCountryList.length"
              >全部</el-checkbox
            >
            <span v-if="!(filterCountryList && filterCountryList.length)">暂无数据</span>

            <el-checkbox-group v-model="checkedCountry">
              <el-checkbox
                :disabled="disabledIdList.includes(country && country.id)"
                v-for="(country, index) in filterCountryList"
                :label="country.id"
                :key="index"
                >{{ country && country.countryCnName }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>

        <div class="right">
          <span class="search-warpper"
            >已选国家/地区：<span class="cancel-all" @click="cancelAllButton">(取消全部)</span></span
          >
          <div class="select">
            <el-checkbox-group v-model="checkedCountry">
              <el-checkbox
                :disabled="disabledIdList.includes(country && country.id)"
                v-for="(country, index) in checkedCountryData"
                :label="country && country.id"
                :key="index"
                >{{ country && country.countryCnName }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="doConfirm">确 定</el-button>
      </span>
    </BaseDialog>
  </div>
</template>

<script>
import { commonFromMixin } from '@/mixins'
import { mapGetters } from 'vuex'
import uniq from 'lodash/uniq'
export default {
  mixins: [commonFromMixin],

  props: {
    form: {
      type: Object,
      default: () => ({})
    },

    allCountry: {
      type: Array,
      required: true
    },

    //禁用国家
    disabledCountryList: {
      type: Array,
      default: () => []
    },

    //历史选中国家列表
    historySelectedCountryList: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      seletcedId: 'all',
      content: '',
      checkedCountry: []
    }
  },

  created() {
    this.initCheckedCountry()
  },

  computed: {
    ...mapGetters(['continentDictCode']),

    checkAll: {
      get({ checkedCountry, filterCountryList }) {
        const curSelectData = checkedCountry.filter((checkeId) => {
          return filterCountryList.find(({ id }) => checkeId == id)
        })
        return curSelectData.length == filterCountryList.length
      },

      set(bool) {
        if (bool) {
          this.checkedCountry.push(...this.filterCountryList.map(({ id }) => id))
          this.checkedCountry = uniq(this.checkedCountry)
        }
        if (!bool) {
          this.checkedCountry = this.checkedCountry.filter((checkedId) => {
            return !this.filterCountryList.find(({ id }) => id == checkedId)
          })
        }
      }
    },

    countryList({ allCountry, seletcedId }) {
      if (seletcedId == 'all') return allCountry
      return allCountry.filter((item) => {
        return item.continentDictCode == this.seletcedId
      })
    },

    filterCountryList({ countryList, content }) {
      if (!content) return countryList
      return countryList.filter(({ countryCnName, twoCharCode }) => {
        return countryCnName.indexOf(content) >= 0 || twoCharCode.toUpperCase().indexOf(content.toUpperCase()) >= 0
      })
    },

    //所有选中数据
    checkedCountryData({ allCountry, checkedCountry }) {
      return allCountry.filter(({ id }) => {
        return checkedCountry.includes(id)
      })
    },

    disabledIdList({ disabledCountryList }) {
      return disabledCountryList.map(({ id }) => id)
    },

    historySelectedIdList({ historySelectedCountryList }) {
      return historySelectedCountryList.map(({ id }) => id)
    },

    newestCheckedCountryData({ checkedCountryData, disabledIdList, historySelectedIdList }) {
      return checkedCountryData.filter(({ id }) => {
        return !(disabledIdList.includes(id) || historySelectedIdList.includes(id))
      })
    },

    isIndeterminate({ filterCountryList, checkedCountry }) {
      const curSelectData = checkedCountry.filter((checkeId) => {
        return filterCountryList.find(({ id }) => checkeId == id)
      })
      if (curSelectData.length == 0) return false
      if (curSelectData.length < filterCountryList.length) return true
      return false
    }
  },

  methods: {
    initCheckedCountry() {
      this.checkedCountry = [...this.disabledIdList, ...this.historySelectedIdList]
    },

    resetCheckedCountry() {
      this.checkedCountry = [...this.disabledIdList]
    },

    updateCheckedCountry() {
      this.checkedCountry.push(...this.disabledIdList, ...this.historySelectedIdList)
      this.checkedCountry = uniq(this.checkedCountry)
    },

    doConfirm() {
      const { newestCheckedCountryData, checkedCountryData, disabledCountryList, historySelectedCountryList } = this

      //返回值
      this.$emit('getSelectedCountryData', {
        newestCheckedCountryData,
        checkedCountryData,
        disabledCountryList,
        historySelectedCountryList
      })
      this.cancel()
    },

    //取消全部
    cancelAllButton() {
      this.resetCheckedCountry()
    }
  }
}
</script>

<style lang="scss" scoped>
.dd-country-button-dialog-warpper {
  ::v-deep {
    .el-dialog__body {
      padding: 0;
    }
  }
}
.add-country-button-add-dialog {
  .select-warpper {
    margin-top: 14px;
    font-size: 14px;
    font-weight: 500;
    color: #1a1a1a;
  }
  .whole-warpper {
    display: flex;
    height: 500px;
    .left {
      width: 527px;
      border-right: 1px solid #dcdee0;
      border-bottom: 1px solid #dcdee0;
      .continent-warpper {
        height: 48px;
        align-items: center;
        border-bottom: 1px solid #dcdee0;
        display: flex;
      }
      .continent-select-warpper {
        padding: 16px 0 0 16px;
        height: 390px;
        overflow: auto;
        .all {
          ::v-deep {
            .el-checkbox__label {
              padding-right: 14px;
            }
          }
        }
        ::v-deep {
          .el-checkbox__inner {
            width: 16px;
            height: 16px;
            border: 1px solid #c8c9cc;
            border-radius: 2px;
          }
          .el-checkbox-group {
            display: inline;
          }
          .el-checkbox__label {
            padding-left: 5px;
            margin-right: 18px;
          }
          .el-checkbox {
            color: #595961;
            font-weight: normal;
            margin-bottom: 13px;
          }
        }
      }
      .search-warpper {
        height: 56px;
        line-height: 56px;
        border-bottom: 1px solid #dcdee0;
        padding-left: 16px;
      }
    }
    .right {
      width: 296px;
      border-bottom: 1px solid #dcdee0;
      .cancel-all {
        color: #3841db;
        cursor: pointer;
      }
      .select {
        padding: 16px 0 0 16px;
        height: 420px;
        overflow: auto;
        .el-checkbox {
          color: #595961;
          font-weight: normal;
          margin-bottom: 13px;
        }
        ::v-deep {
          .el-checkbox__label {
            margin-right: 13px;
          }
        }
      }
      .search-warpper {
        display: inline-block;
        height: 56px;
        width: 100%;
        line-height: 56px;
        border-bottom: 1px solid #dcdee0;
        padding-left: 16px;
        color: #1a1a1a;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
  .el-radio-group {
    .el-radio.el-radio.el-radio.el-radio.el-radio.el-radio {
      margin-right: 12px;
     
    }
  }
::v-deep {
  .el-checkbox {
    .el-checkbox__label.el-checkbox__label.el-checkbox__label.el-checkbox__label {
      margin-right: 0;
    }
  }
  .el-radio-button__inner {
    background: rgb(245, 245, 245);
  }
  .el-radio-button__inner.el-radio-button__inner {
    margin: 0;
    padding: 9px;
    height: 28px;
    line-height: 10px;
    margin-right: 12px;
    border: 1px solid $border-color;
    border-radius: 2px;
    box-shadow:none;
  }
  .el-radio-button.is-active {
    .el-radio-button__inner.el-radio-button__inner {
      border-color: $color-primary;
    }
  }


}
</style>